<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        The
        <stemble-latex content="$\text{pK}_\text{b}$" />
        of nicotine,
        <chemical-latex content="C10H14N2," />
        is 5.98 at 25&nbsp;<sup>o</sup>C. For a
        <number-value :value="concA" unit="\text{M}" />
        solution of nicotine, answer each of the questions below:
      </p>

      <p class="mb-2">
        a) What is the
        <stemble-latex content="$\text{K}_\text{b}$" />
        of nicotine?
      </p>

      <calculation-input
        v-model="inputs.Kb"
        class="mb-5"
        prepend-text="$\text{K}_\text{b}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) Fill in the ICE table below inputting
        <stemble-latex content="$\mathrm{x}$" />
        for any relative changes. Be sure to indicate the direction of change, i.e. +/-. Be sure to
        use the proper number of significant figures. Any zeroes should be denoted as 0. The
        equilibrium row should contain the
        <stemble-latex content="$\mathrm{x}$" /> variable. Note that you will not be given the
        layout of the table on an actual test. In the table, nicotine and its conjugate acid are
        denoted as N and NH<sup>+</sup>, respectively.
      </p>

      <v-simple-table class="mb-8" dense>
        <thead class="text-center">
          <tr>
            <th style="vertical-align: middle; text-align: center; font-size: 15px" />
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="N(aq)" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$+$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="H2O(l)" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="<=>" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="NH^+(aq)" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$+$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="OH^-(aq)" />
            </th>
          </tr>

          <tr>
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$\textbf{I}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialB" class="text-center" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$-$" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialBH" class="text-center" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialOH" group-size="sm" class="text-center" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$\textbf{C}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeB" class="text-center" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$-$" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeBH" class="text-center" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeOH" class="text-center" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$\textbf{E}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.equilibriumB" class="text-center" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$-$" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.equilibriumBH" class="text-center" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.equilibriumOH" class="text-center" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-2">
        c) What is the concentration of
        <chemical-latex content="OH-" />
        once the system reaches equilibrium?
      </p>

      <calculation-input
        v-model="inputs.OHeq"
        class="mb-5"
        prepend-text="$\ce{[OH^-]:}$"
        append-text="$\mathrm{M}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        d) What is the concentration of
        <chemical-latex content="H3O+" />
        once the system reaches equilibrium?
      </p>

      <calculation-input
        v-model="inputs.H3Oeq"
        class="mb-5"
        prepend-text="$\ce{[H3O^+]:}$"
        append-text="$\mathrm{M}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        e) What is the
        <stemble-latex content="$\textbf{pOH}$" />
        of the solution?
      </p>

      <calculation-input
        v-model="inputs.pOH"
        class="mb-5"
        prepend-text="$\mathrm{pOH:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        f) What is the
        <stemble-latex content="$\textbf{pH}$" />
        of the solution?
      </p>

      <calculation-input
        v-model="inputs.pH"
        class="mb-5"
        prepend-text="$\mathrm{pH:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        g) What is the
        <stemble-latex content="$\text{pK}_\text{a}$" />
        of the conjugate acid of nicotine,
        <chemical-latex content="C10H15N2+?" />
      </p>

      <calculation-input
        v-model="inputs.pKa"
        class="mb-0"
        prepend-text="$\text{pK}_\text{a}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import IceInput from '../inputs/IceInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question542',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    IceInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Kb: null,
        initialB: null,
        initialOH: null,
        initialBH: null,
        changeB: null,
        changeOH: null,
        changeBH: null,
        equilibriumB: null,
        equilibriumOH: null,
        equilibriumBH: null,
        OHeq: null,
        H3Oeq: null,
        pOH: null,
        pH: null,
        pKa: null,
      },
    };
  },
  computed: {
    concA() {
      return this.taskState.getValueBySymbol('concA').content;
    },
  },
};
</script>
